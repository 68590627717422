<template>
  <div v-if="!loading" id="app" :class="{ appPadding: $store.state.appPadding }">
    <logout :class="{ hidden: hideForThisRoute }" />

    <div class="main container" :class="{ paddedhight: $store.state.appPadding, fullheight: !$store.state.appPadding }">
      <router-view />
    </div>
    <footer class="main-footer" :class="{ hidden: hideForThisRoute }">
      <div class="main-footer-container">
        <div class="horizontal">
          <a :href="this.webAddress"><img class="warhallHomeButton" src="/Images/LogoAndWarhall.png" /></a>
          <div class="logos">
            <a href="https://discord.com/invite/MVdhB8bxSj" target="_blank">
              <img class="footerLogo" src="/Images/Discord Logo.png" />
            </a>
            <a href="https://www.youtube.com/c/Warhall" target="_blank">
              <img class="footerLogo" src="/Images/Youtube Logo.png" />
            </a>
            <a href="https://www.facebook.com/Warhall-179162160222256/" target="_blank">
              <img class="footerLogo" src="/Images/Facebook Logo.png" />
            </a>
            <a href="https://www.twitch.tv/warhalltv" target="_blank">
              <img class="footerLogo" src="/Images/Twitch Logo.png" />
            </a>
          </div>
          <div class="notes">
            <a :href="this.webAddress + '/legal'" class="smallFont" target="_blank"> Privacy Policy </a>
            <div class="smallFont" target="_blank">2024 by Greenfeet S.L. </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import logout from "@/components/logout";
import firebase from "firebase/app";
import "firebase/database";
import { loadUsername } from "@/assets/js/common.js";
require("firebase/auth");

export default {
  name: "App",
  components: { logout },

  computed: {
    hideForThisRoute() {
      let names = ["directstore"];
      return names.includes(this.$route.name);
    },

    webAddress() {
      return this.$store.state.webAddress
    }
  },

  data() {
    return {
      loading: true
    };
  },

  async created() {
    var firebaseConfig = {
      apiKey: "AIzaSyCrzXx_-VwtiykSbf_oCxa_3heN8pNrTbg",
      authDomain: "warhall-eu.firebaseapp.com",
      databaseURL: "https://warhall-eu-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "warhall-eu",
      storageBucket: "warhall-eu.appspot.com",
      messagingSenderId: "453139481912",
      appId: "1:453139481912:web:6c34b177a29df0214cadcd",
      measurementId: "G-T3VVXXVN8V",
    };
    // Initialize Firebase
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    let res = await fetch(`/retrieveToken`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    let claim = await res.json();
    if (claim == null) {
      this.$store.commit("setLogin", null);
      this.$store.commit("setID", null);
    } else if (claim.email != undefined) {
      // Load user name and data from DB
      try {
        let result = await loadUsername(this.$store, this.$router);
        if (result) {
          this.$store.commit("setLogin", claim.email);
          this.$store.commit("setID", claim.user_id);
        }
      } catch (err) {
        this.$router.push({ name: "login" });
      }
    }
    this.loading = false;

    // Pull all paths from firebase middleware
    const database = firebase.database();

    // Read server
    database.ref('WARHALLTEST/CLIENT/Server').once('value', (snapshot) => {
      const data = snapshot.val();
      this.$store.commit("setServerAddress", data);
    });

    // Read data
    database.ref('WARHALLTEST/CLIENT/DataURL').once('value', (snapshot) => {
      const data = snapshot.val();
      this.$store.commit("setDataAddress", data);
    });

    // Read web
    database.ref('WARHALLTEST/CLIENT/Web').once('value', (snapshot) => {
      const data = snapshot.val();
      this.$store.commit("setWebAddress", data);
    });
  },

  methods: {},
};
</script>

<style scoped>
.horizontal {
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  width: 100vw;
  align-items: center;
  grid-gap: 10px;
  align-content: center;
  height: 100%;
}

.smallFont {
  font-size: 0.5em;
}

.footerLogo {
  width: 32px;
}

.notes {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
}

.logos {
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
}
</style>

<style>

.horizontal {
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  width: 100vw;
  max-width: 900px;
  align-items: center;
  grid-gap: 10px;
  align-content: center;
  margin: 0 auto;
  height: 100%;
}

.feedback {
  margin: 20px;
  color: red;
  text-align: center;
}

.feedbackOK {
  margin: 20px;
  color: rgb(0, 247, 82);
  text-align: center;
}

/* MVP.css v1.5 - https://github.com/andybrewer/mvp */
:root {
  --border-radius: 5px;
  --box-shadow: 2px 2px 10px;
  --color: #118bee;
  --color-accent: #118bee0b;
  --color-bg: #fff;
  --color-bg-secondary: #e9e9e9;
  --color-secondary: #920de9;
  --color-secondary-accent: #920de90b;
  --color-shadow: #f4f4f4;
  --color-text: #000;
  --color-text-secondary: #999;
  --font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
  --hover-brightness: 1.2;
  --justify-important: center;
  --justify-normal: left;
  --line-height: 150%;
  --width-card: 285px;
  --width-card-medium: 460px;
  --width-card-wide: 800px;
  --width-content: 1080px;
}

/*
@media (prefers-color-scheme: dark) {
  :root {
      --color: #0097fc;
      --color-accent: #0097fc4f;
      --color-bg: #333;
      --color-bg-secondary: #555;
      --color-secondary: #e20de9;
      --color-secondary-accent: #e20de94f;
      --color-shadow: #bbbbbb20;
      --color-text: #f7f7f7;
      --color-text-secondary: #aaa;
  }
}
*/

/* Layout */
article aside {
  background: var(--color-secondary-accent);
  border-left: 4px solid var(--color-secondary);
  padding: 0.01rem 0.8rem;
}

body {
  background: var(--color-bg);
  color: var(--color-text);
  line-height: var(--line-height);
  margin: 0;
  overflow-x: hidden;
  padding: 1rem 0;
}

header,
main {
  margin: 0 auto;
  max-width: var(--width-content);
  padding: 2rem 1rem;
}

footer {
  margin: 0 auto;
  padding: 2rem 1rem;
}

.hidden {
  display: none;
}

hr {
  background-color: var(--color-bg-secondary);
  border: none;
  height: 1px;
  margin: 4rem 0;
}

section {
  flex-wrap: wrap;
  justify-content: var(--justify-important);
}

section aside {
  border: 1px solid var(--color-bg-secondary);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow) var(--color-shadow);
  margin: 1rem;
  padding: 1.25rem;
  width: var(--width-card);
}

section aside:hover {
  box-shadow: var(--box-shadow) var(--color-bg-secondary);
}

section aside img {
  max-width: 100%;
}

/* Headers */
article header,
div header,
main header {
  padding-top: 0;
}

header {
  text-align: var(--justify-important);
}

header a b,
header a em,
header a i,
header a strong {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

header nav img {
  margin: 1rem 0;
}

section header {
  padding-top: 0;
  width: 100%;
}

/* Nav */
nav {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 7rem;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline-block;
  margin: 0 0.5rem;
  position: relative;
  text-align: left;
}

/* Nav Dropdown */
nav ul li:hover ul {
  display: block;
}

nav ul li ul {
  background: var(--color-bg);
  border: 1px solid var(--color-bg-secondary);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow) var(--color-shadow);
  display: none;
  height: auto;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
  top: 1.7rem;
  width: auto;
}

nav ul li ul li,
nav ul li ul li a {
  display: block;
}

/* Typography */
code,
samp {
  background-color: var(--color-accent);
  border-radius: var(--border-radius);
  color: var(--color-text);
  display: inline-block;
  margin: 0 0.1rem;
  padding: 0rem 0.5rem;
  text-align: var(--justify-normal);
}

details {
  margin: 1.3rem 0;
}

details summary {
  font-weight: bold;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height);
}

mark {
  padding: 0.1rem;
}

ol li,
ul li {
  padding: 0.2rem 0;
}

p {
  margin: 0;
  padding: 0;
}

pre {
  margin: 1rem 0;
  max-width: var(--width-card-wide);
  white-space: pre-line;
}

pre code,
pre samp {
  padding: 1rem 2rem;
}

small {
  color: var(--color-text-secondary);
}

sup {
  background-color: var(--color-secondary);
  border-radius: var(--border-radius);
  color: var(--color-bg);
  font-size: xx-small;
  font-weight: bold;
  margin: 0.2rem;
  padding: 0.2rem 0.3rem;
  position: relative;
  top: -2px;
}

/* Links */
a {
  /* color: var(--color-secondary); */
  display: inline-block;
  font-weight: bold;
  text-decoration: underline;
}

a:hover {
  filter: brightness(var(--hover-brightness));
  text-decoration: underline;
  color: #118bee;
}

a b,
a em,
a i,
a strong,
button {
  border-radius: var(--border-radius);
  /* display: inline-block; */
  font-size: medium;
  font-weight: bold;
  /* line-height: var(--line-height);
  margin: 0.5rem 0;
  padding: 1rem 2rem; */
}

input {
  width: 100%;
}

input,
text {
  color: black;
}

button {
  font-family: "Catamaran";
  font-weight: normal;
  color: white;
  margin: 0 auto;
  display: block;
  padding: 1rem 2rem;
}

button:hover {
  cursor: pointer;
  filter: brightness(var(--hover-brightness));
}

a b,
a strong,
button {
  background-color: var(--color);
  border: 2px solid var(--color);
  color: var(--color-bg);
}

a em,
a i {
  border: 2px solid var(--color);
  border-radius: var(--border-radius);
  color: var(--color);
  display: inline-block;
  padding: 1rem 2rem;
}

/* Images */
figure {
  margin: 0;
  padding: 0;
}

figure img {
  max-width: 100%;
}

figure figcaption {
  color: var(--color-text-secondary);
}

/* Forms */

button:disabled,
input:disabled {
  background: var(--color-bg-secondary);
  border-color: var(--color-bg-secondary);
  color: var(--color-text-secondary);
  cursor: not-allowed;
}

button[disabled]:hover {
  filter: none;
}

form {
  border-radius: var(--border-radius);
  display: block;
  max-width: var(--width-card-wide);
  min-width: var(--width-card);
  padding: 1.5rem;
  text-align: var(--justify-normal);
}

form header {
  margin: 1.5rem 0;
  padding: 1.5rem 0;
}

input,
label,
select,
textarea {
  display: block;
  font-size: inherit;
  max-width: var(--width-card-wide);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  font-weight: normal;
  position: relative;
  top: 1px;
}

input,
select,
textarea {
  border: 1px solid var(--color-bg-secondary);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  padding: 0.4rem 0.8rem;
}

input[readonly],
textarea[readonly] {
  background-color: var(--color-bg-secondary);
}

label {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

/* Tables */
table {
  border: 1px solid var(--color-bg-secondary);
  border-radius: var(--border-radius);
  border-spacing: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
}

table td,
table th,
table tr {
  padding: 0.4rem 0.8rem;
  text-align: var(--justify-important);
}

table thead {
  background-color: var(--color);
  border-collapse: collapse;
  border-radius: var(--border-radius);
  color: var(--color-bg);
  margin: 0;
  padding: 0;
}

table thead th:first-child {
  border-top-left-radius: var(--border-radius);
}

table thead th:last-child {
  border-top-right-radius: var(--border-radius);
}

/* table thead th:first-child,
table tr td:first-child {
  text-align: var(--justify-normal);
} */

/* Quotes */
blockquote {
  display: block;
  font-size: x-large;
  line-height: var(--line-height);
  margin: 1rem auto;
  max-width: var(--width-card-medium);
  padding: 1.5rem 1rem;
  text-align: var(--justify-important);
}

blockquote footer {
  color: var(--color-text-secondary);
  display: block;
  font-size: small;
  line-height: var(--line-height);
  padding: 1.5rem 0;
}

/* Custom styles */
</style>

<style>
.appPadding {
  padding-top: 60px;
  padding-bottom: 80px;
}

.paddedhight {
  min-height: calc(100vh);
}

.fullheight {
  min-height: 100vh;
}

#login {
  margin: 0 auto;
  width: 50%;
}

@font-face {
  font-family: "Catamaran";
  src: url("/Fonts/Catamaran.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: "Catamaran";
  color: white;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

.nav ul {
  margin: 0;
}

.nav li {
  display: inline;
}

.nav a {
  display: inline-block;
  padding: 0.5em;
  color: white;
  text-decoration: none;
}

.main-nav {
  text-align: center;
  font-size: 1.1em;
  font-weight: lighter;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.main-nav li {
  padding: 0 5%;
}

.nav a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.main-header {
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url("/Images/Header Background.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  padding-bottom: 30px;
}

.band-name {
  text-align: center;
  margin: 0;
  font-size: 4em;
  font-family: "Catamaran";
  font-weight: normal;
  color: white;
}

.band-name-large {
  margin: 1em;
  font-size: 4em;
}

.container {
  width: 100vw;
  max-width: 900px;
  margin: 0 auto;
  background-color: #1b262b;
}
.section-header {
  font-family: "Catamaran";
  font-weight: normal;
  color: white;
  text-align: center;
  font-size: 2.5em;
  padding-top: 30px;
}

.about-band-image {
  float: left;
  height: 200px;
  width: 200px;
  margin: 15px;
  border-radius: 50%;
}

.main-footer {
  background-color: #1b262b;
  color: white;
  padding: 20px 0;
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 15;
}

.main-footer-container {
  display: flex;
  align-items: center;
  width: 900px;
  margin: 0 auto;
}

.main-footer-container ul {
  flex-grow: 1;
  text-align: end;
}

.footer-nav li {
  padding: 0 0.5em;
}

.footer-nav img {
  width: 30px;
  height: 30px;
}

.btn {
  text-align: center;
  vertical-align: middle;
  padding: 0.67em 0.67em;
  cursor: pointer;
}

.btn-header {
  margin: 0.5em 15% 2em 15%;
  color: white;
  border: 2px solid #1b262b;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  font-size: 1.5em;
  font-weight: lighter;
  padding-left: 2em;
  padding-right: 2em;
}

.btn-header:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.btn-play {
  display: block;
  margin: 0 auto;
  color: #2d9cdb;
  font-size: 4em;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 0;
  text-align: center;
}

.btn-primary {
  color: white;
  background-color: #56ccf2;
  border: none;
  border-radius: 0.3em;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: #2d9cdb;
}

.tour-row {
  border-bottom: 1px solid black;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.tour-row:last-child {
  border: none;
}

.tour-item {
  display: inline-block;
  padding-right: 0.5em;
}

.tour-date {
  color: #555;
  width: 11%;
  font-weight: bold;
}

.tour-city {
  width: 24%;
}

.tour-arena {
  width: 42%;
}

.tour-btn {
  max-width: 19%;
}
.btn-danger {
  color: white;
  background-color: #eb5757;
  border: none;
  border-radius: 0.3em;
  font-weight: bold;
}

.btn-friendly {
  color: white;
  background-color: greenyellow;
  border: none;
  border-radius: 0.3em;
  font-weight: bold;
}

.btn-danger:hover {
  background-color: #cc4c4c;
}
.btn-purchase {
  display: block;
  margin: 40px auto 80px auto;
  font-size: 1.75em;
}

#app {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 212, 255, 1) 50%, rgba(2, 0, 36, 1) 100%);
}
.background {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.warhallHomeButton {
  width: 120px;
}

.content-section {
  padding-bottom: 50px;
  padding-right: 50px;
  padding-left: 50px;
}

.screenSize {
  /* Set the element to take up the full width of the viewport */
  width: 100vw;
  
  /* Center the element horizontally within the viewport */
  margin-left: auto;
  margin-right: auto;
  
  /* Set a maximum width of 900px */
  max-width: 900px;
}

/*Targets screens with a maximum width of 767 pixels (which typically 
includes most mobile devices) and an orientation of landscape*/
@media only screen and (max-width: 900px) and (orientation: landscape) {
  .logout {
    display: none;
  }

  .main-footer {
    display: none;
  }

  .appPadding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
</style>
