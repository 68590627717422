<template>
  <div class="games">
    <button :disabled="items == null || items.length == 0" @click.prevent="show"> 
      All Games 
    </button>
    <div v-show="!hidden">
      <table border="1">
        <thead>
          <tr>
            <th v-if="!isMobile">Date</th>
            <th>Player 1</th>
            <!-- <th>Army</th> -->
            <th>Player 2</th>
            <!-- <th>Army</th> -->
            <th>Battle Report</th>
          </tr>
        </thead>
        <tbody id="example-1">
          <tr v-for="(item, index) in items" :key="index">
            <!-- Check if the index is odd using a conditional statement -->
            <template v-if="index % 2 !== 0">
              <!-- Render the item if the index is odd -->
              <td v-if="!isMobile">{{ item.Start }}</td>
              <td>{{ item.Opponent }} <br> <img v-if=validArmyName(item.OpponentArmy) :src="'/Images/ArmyIcons/' + item.OpponentArmy + '.png'" width="100" height="100"/></td>
              <!-- <td><img v-if=validArmyName(item.OpponentArmy) :src="'/Images/ArmyIcons/' + item.OpponentArmy + '.png'" width="100" height="100"/></td> -->
              <td>{{ items[index-1].Opponent }} <br> <img v-if=validArmyName(item.MyArmy) :src="'/Images/ArmyIcons/' + item.MyArmy + '.png'" width="100" height="100"/></td>
              <!-- <td><img v-if=validArmyName(item.MyArmy) :src="'/Images/ArmyIcons/' + item.MyArmy + '.png'" width="100" height="100"/></td> -->
              <td>
                <button @click="battleReport(index)" v-if="item.GameId != null">Report</button>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { responseErrorHandling } from "@/assets/js/common.js";

export default {
  data() {
    return {
      hidden: true,
      items: null,
    };
  },

  mounted() {
    this.getGamesHistory();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 900; // Set your desired screen width for mobile
    },
  },

  methods: {
    async getGamesHistory() {
      let res = await fetch(`/GetAllPlayedGames`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) 
      {
        return;
      }
      let response = await res.json();

      if (response.error != 0) {
        return;
      }
      else{
        this.items = response.result;
        for (let i = 0; i < this.items.length; i++) {
          let item = this.items[i];
          item.Start = this.formatSQLDateToHuman(item.Start);
        }
      }
    },

    async battleReport(index) {
      var date = this.items[index].Start;
      date = date.replace(/\s+/g, '_');
      var displayName = this.items[index].Opponent + "_VS_" + this.items[index-1].Opponent + "_"  + date;
      var gameid = this.items[index].GameId;
      this.$router.push({ 
        name: "battlereport",
        query: { id: gameid, BattlleReport: displayName }
      });
    },

    show() {
      this.hidden = !this.hidden;
    },

    formatSQLDateToHuman(sqlDate) {
      let year = sqlDate.substring(0, 4);
      let month = sqlDate.substring(5, 7);
      let day = sqlDate.substring(8, 10);
      let date = new Date(year, month - 1, day);
      let humanReadableDate = date.toDateString();
      return humanReadableDate.substring(4, humanReadableDate.length);
    },
    
    validArmyName(val)
    {
      if (val === '' || val == null)
      {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
button {
  padding: 0.5rem 1rem;
  margin-bottom: 20px;
}

img {
  height: 32px;
  width: 32px;
}
</style>
